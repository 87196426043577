import React, { Component } from 'react';
import { authHeader } from '../helpers/auth-header';

export class CustomerList extends Component {
    static displayName = CustomerList.name;

    constructor(props) {
        super(props);
        this.state = { customers: [], loading: true };
    }

    componentDidMount() {
        this.populateCustomerData();
    }

    static formatKey(theKey) {
        return (!theKey || theKey === "1") ? "" : theKey.toUpperCase().match(/\w{4}(?=\w{2,4})|\w+/g).join("-");
    }
    static renderCustomersTable(customers) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Code</th>
                        <th>Key</th>
                        <th>Company Name</th>
                        <th>USB Serial</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map(customer =>
                        <tr key={customer.id}>
                            <td>{customer.id}</td>
                            <td>{customer.cdcode}</td>
                            <td>{this.formatKey(customer.cdkey)}</td>
                            <td>{customer.companyName}</td>
                            <td>{customer.usbserial}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : CustomerList.renderCustomersTable(this.state.customers);

        return (
            <div>
                <h1 id="tabelLabel" >Customer List</h1>
                <p>This component demonstrates fetching data from the mysql server.</p>
                {contents}
            </div>
        );
    }

    async populateCustomerData() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        const response = await fetch('customer', requestOptions);
        const data = await response.json();
        this.setState({ customers: data, loading: false });
    }
}
