import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
//import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
//import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../custom.css';
import { authHeader } from '../helpers/auth-header';
import { dateFormatter, formatKey } from '../helpers/miscGridUtils';

export class CustomerGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            quickFilterText: '',
            pageSize: 20,
            columnDefs: [
                { field: "id", headerName: "ID", width: 80, filter: 'agNumberColumnFilter', cellStyle: { textAlign: 'center', color: 'red' }, sort: 'desc', pinned: 'left' },
                { field: "version", headerName: "Version", width: 80, filterParams: { defaultOption: 'startsWith', } },
                { field: "edition", headerName: "Edition", width: 80, wrapText: true, cellStyle: { textAlign: 'center' }, cellRenderer: editionValueRenderer, },
                { field: "cdcode", headerName: "CD Code", width: 80, cellStyle: { textAlign: 'center', color: 'green' } },
                { field: "cdkey", headerName: "CD Key", width: 150, valueGetter: cdkeyValueGetter, },
                { field: "hdserial", headerName: "HD Serial", width: 90, valueFormatter: serialFormatter, },
                { field: "usbserial", headerName: "USB Serial", width: 90, wrapText: true, autoHeight: true, valueFormatter: serialFormatter, },
                { field: "serverMacaddress", headerName: "ServerMACAddress", width: 140, filter: false },
                { field: "companyName", headerName: "Company Name", width: 200, },
                { field: "ecompanyName", headerName: "E Company Name", width: 200, },
                { field: "companyAddress", headerName: "Company Address", width: 100, },
                { field: "ecompanyAddress", headerName: "E Company Address", width: 100, },
                { field: "taxId", headerName: "Tax ID", width: 130, },
                { field: "snkey", headerName: "SN Key", width: 80, hide: true, },
                { field: "sndate", headerName: "SN Date", width: 80, hide: true, },
                { field: "firstInstallDate", headerName: "First Install Date", width: 100, filter: 'agDateColumnFilter', filterParams: dateFilterParams, valueFormatter: dateFormatter, },
                { field: "registerDate", headerName: "Register Date", width: 100, filter: 'agDateColumnFilter', filterParams: dateFilterParams, valueFormatter: dateFormatter, },
                { field: "demoType", headerName: "Demo Type", width: 50, filter: 'agNumberColumnFilter', },
                { field: "registerType", headerName: "Register Type", width: 50, filter: 'agNumberColumnFilter', },
                { field: "licenseList", headerName: "License List", width: 1000 },
            ],
            defaultColDef: { resizable: true, sortable: true, filter: true, filterParams: { buttons: ['apply', 'reset',] }, },
            rowData: [],
            loading: true,
        }
    }

    async loadCustomersFromServer() {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        const response = await fetch('/customer', requestOptions);
        const data = await response.json();
        this.setState({
            rowData: data,
            loading: false,
        });
    }

    componentDidMount() {
        //must use this event to show Loading...
        this.loadCustomersFromServer();
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        //params.api.sizeColumnsToFit();

        //this.loadCustomersFromServer();
    };

    pageSizeChange = (e) => {
        let numInPage = parseInt(e.target.value);
        if (numInPage) {
            this.setState({ pageSize: numInPage });
            this.gridApi.paginationSetPageSize(numInPage);
        }
    }

    renderSearchBox() {
        return (
            <div className="search-header">
                Search:&nbsp;
                <input
                    type="text"
                    value={this.state.quickFilterText}
                    placeholder="Filter any column..."
                    onChange={(e) => {
                        this.setState({ quickFilterText: e.target.value });
                    }}
                />
                <button
                    onClick={() => {
                        this.setState({
                            quickFilterText: ''
                        });
                    }} >Clear
                </button>
                &nbsp;Page Size:&nbsp;
                <select onChange={this.pageSizeChange} value={this.state.pageSize}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="1000">1000</option>
                </select>
            </div>
        );
    }
    renderCustomersGrid() {
        let maxHeight = window.innerHeight - 200;

        return (
            <div id='myGrid' className="ag-theme-balham" style={{ height: maxHeight }} >
                <AgGridReact
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableSorting={true}
                    enableFilter={true}
                    pagination={true}
                    paginationPageSize={this.state.pageSize}
                    onGridReady={this.onGridReady}
                    quickFilterText={this.state.quickFilterText}
                >
                </AgGridReact>
            </div>
        );
    }

    render() {
        let contents =
            this.state.loading ?
                <p><em>Loading...</em></p>
                :
                this.renderCustomersGrid();

        return (
            <div>
                <h2 id="tabelLabel" >NeoSME Customer List</h2>
                {this.renderSearchBox()}
                {contents}
            </div>
        );
    }
}

function serialFormatter(params) {
    //return '\xA3' + formatNumber(params.value);
    if (!params.value)
        return '';

    var result = '';
    params.value.split(/\s*:\s*/).forEach(function (myString) {
        result = result + formatKey(myString) + ' ';
    });
    return result;
}
function cdkeyValueGetter(params) {
    //จริง ๆ cdkey ก็ใช้ format ได้แหละเพราะไม่มีการคำนวณข้าม field แต่อยากทำตัวอย่าง getter ด้วย
    //return params.data.a + params.data.b;
    return formatKey(params.data.cdkey);
}

function getEditionString(_edition, _expDate) {
    if (_edition == "")
        return "";

    var result = "";
    switch (_edition) {
        case "1":
            result = "Value";
            break;
        case "2":
            result = "Std";
            break;
        case "3":
            result = "VLP";
            break;
        case "4":
            result = "Pro";
            break;
        case "5":
            result = "ST";
            break;
        case "6":
            result = "SL";
            break;
        case "7":
            result = "RC";
            break;
        case "8":
            result = "EP";
            break;
        case "20":
            result = "Mfg";
            break;
    }
    return _edition + (parseInt(_expDate.substr(0, 4)) > "2999" ? "" : "d") + "(" + result + ")";
}

function editionValueRenderer(params) {
    var _licenseList = params.data.licenseList;
    var _edition = "";
    _licenseList.split('|').forEach(function (_aEdition) {
        var _myEdition = _aEdition.split('+', );
        try {
            if (_edition == "")
                _edition = getEditionString(_myEdition[0], _myEdition[3]);
            else if (_myEdition[0] != "")
                _edition = _edition+"<br />"+getEditionString(_myEdition[0], _myEdition[3]);
        }
        catch (err) {
        }
    });
    return _edition;
}

var dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue; //JSON Date format: "2017-01-17T00:00:00"
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split(/[-T]+/); //split both - and T
        var cellDate = new Date(
            Number(dateParts[0]),
            Number(dateParts[1]) - 1,
            Number(dateParts[2])
        );

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
    browserDatePicker: true,
    minValidYear: 2000,
};
