export function dateFormatter(params) {
    if (!params.value)
        return '';

    var sdate = params.value.replace("\"", "");
    //var date = new Date(sdate);
    //return date.getFullYear() + '/' + (date.getMonth()+1) + '/' + date.getDate() + " " +sdate;
    var xdate = sdate.split('T');
    return xdate[0];
}

export function formatKey(theKey) {
    return (!theKey || theKey === "1") ? "" : theKey.toUpperCase().match(/\w{4}(?=\w{2,4})|\w+/g).join("-");
}