import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import ReactDataGrid from "react-data-grid";
import { authHeader } from '../helpers/auth-header';
import { objToQueryString } from '../helpers/objToQueryString';
//import { formatKey } from '../helpers/miscGridUtils';

const columns = [
    { key: "id", name: "ID", }, //key: true, frozen: true
    { key: "cdcode", name: "CD Code" },
    { key: "cdkey", name: "CD Key" },
    { key: "companyName", name: "Company Name" },
    { key: "usbserial", name: "USB Serial", editable: true },
];

export class CustList extends Component {
    static propTypes = {
        perPage: PropTypes.number.isRequired,
        data: PropTypes.array.isRequired,
    };

    //onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    //    this.setState(state => {
    //        const rows = state.rows.slice();
    //        for (let i = fromRow; i <= toRow; i++) {
    //            rows[i] = { ...rows[i], ...updated };
    //        }
    //        return { rows };
    //    });
    //};

    //manualGrid() {
    //    return (
    //        <table className='table table-striped' aria-labelledby="tabelLabel">
    //            <thead>
    //                <tr>
    //                    <th>Id</th>
    //                    <th>Code</th>
    //                    <th>Key</th>
    //                    <th>Company Name</th>
    //                    <th>USB Serial</th>
    //                </tr>
    //            </thead>
    //            <tbody>
    //                {this.props.data.map(customer =>
    //                    <tr key={customer.id}>
    //                        <td>{customer.id}</td>
    //                        <td>{customer.cdcode}</td>
    //                        <td>{this.formatKey(customer.cdkey)}</td>
    //                        <td>{customer.companyName}</td>
    //                        <td>{customer.usbSerial}</td>
    //                    </tr>
    //                )}
    //            </tbody>
    //        </table>
    //    );
    //}

    render() {
        //let a1 = this.manualGrid();
        let maxHeight = window.innerHeight-200;
        let gridRowHeight = 35;
        let gridMinHight = (gridRowHeight * (this.props.perPage + 1)) + 5;
        if (gridMinHight > maxHeight) gridMinHight = maxHeight;

        return (
            <div>
                <ReactDataGrid
                    columns={columns}
                    rowGetter={i => this.props.data[i]}
                    rowsCount={this.props.perPage}
                    //onGridRowsUpdated={this.onGridRowsUpdated}
                    enableCellSelect={false}
                    rowHeight={gridRowHeight}
                    minHeight={gridMinHight}
                />
            </div>
        );
    }
}

export class CustomerPage extends Component {
    static displayName = CustomerPage.name;

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            perPage: 30,
            pageCount: 0,
            totalCount: 0,
            offset: 0,
        };
    }

    componentDidMount() {
        this.loadCustomersFromServer();
    }

    renderCustomersTable(data) {
        return (
            <div className="commentBox">
                <CustList data={data} perPage={this.state.perPage} />
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
                <div>Total: {this.state.totalCount} Customer(s).</div>
            </div>)
    }

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.perPage);

        this.setState({ offset: offset }, () => {
            this.loadCustomersFromServer();
        });
    };

    async loadCustomersFromServer() {
        const queryString = objToQueryString({
            limit: this.state.perPage,
            offset: this.state.offset
        });
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        const response = await fetch('/customer/getjson?' + queryString, requestOptions);
        const data = await response.json();
        this.setState({
            data: data.customers,
            pageCount: Math.ceil(data.meta.total_count / data.meta.limit),
            totalCount: data.meta.total_count,
            loading: false
        });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderCustomersTable(this.state.data);

        return (
            <div>
                <h2 id="tabelLabel" >NeoSME Customer List</h2>
                {contents}
            </div>
        );
    }
}
