import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { PrivateRoute } from './components/PrivateRoute';
import { Home } from './pages/Home';
import { LoginPage } from './pages/LoginPage';
import { FetchData } from './pages/FetchData';
import { Counter } from './pages/Counter';
import { CustomerList } from './pages/CustomerList';
import { CustomerPage } from './pages/CustomerPage';
import { CustomerGrid } from './pages/CustomerGrid';
import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    //constructor() {
    //    super();
    //}

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path="/login" component={LoginPage} />
                <Route path='/counter' component={Counter} />
                <PrivateRoute
                    path='/fetch-data'
                    component={FetchData} />
                <PrivateRoute
                    path='/customer-list'
                    component={CustomerList} />
                <PrivateRoute
                    path='/customer-page'
                    component={CustomerPage} />
                <PrivateRoute
                    path='/customer-grid'
                    component={CustomerGrid} />
            </Layout>
        );
    }
}
